$(document).ready(function()
{
    displayFooterBelowWindow();

    $(window).on('resize', function()
    {
        displayFooterBelowWindow();
    });

    $('.server-ip-quick').on('click', function()
    {
        $(this).select();
    });

    $('.server-ip-copy').on('click', function()
    {
        $(this).siblings('.server-ip-quick').select();

        document.execCommand('copy');
    });

    $('.toggle-servers-deleted').on('click', function()
    {
        $(this).hide();

        $('.servers-deleted .table').toggleClass('table-hide');
    });

    $('[data-toggle="tooltip"]').tooltip();

    displayServerInfo([
        'title',
        'description',
        'tags',
        'quick'
    ]);

    $('#game-search').on('input', function()
    {
        var searchFor = $(this).val().toLowerCase();

        $('.game-holder').each(function()
        {
            var gameTitle = $(this).attr('data-game').toLowerCase();

            if(gameTitle == '' || gameTitle.indexOf(searchFor) > -1)
            {
                $(this).show();
            }
            else
            {
                $(this).hide();
            }
        });
    });

    $('#server-toggle-options input').on('click', function()
    {
        var cookieName  = 'toggle-' + $(this).attr('name'),
            targetClass = '.sh-'    + $(this).attr('name');

        if(!Cookies.get(cookieName))
        {
            Cookies.set(cookieName, 1, { expires: 365 });
        }
        else
        {
            Cookies.remove(cookieName);
        }

        $(targetClass).toggle();
    });

    $('#tags').selectize({
        delimiter: ',',
        persist: false,
        create: function(input) {
            return {
                value: input,
                text: input
            }
        },
        maxItems: 10
    });

    if($('.server-form').hasClass('server-create'))
    {
        var pickGame = window.location.hash.substr(1);

        if(pickGame != '')
        {
            $('.game-select option[data-slug=' + pickGame + ']').prop('selected', true);
        }

        // $('.pre-game-select').hide();

        var gameSelect = $('.server-form select');

        if (gameSelect.val())
        {
            updateServerForm(getAllowIpOnServerCreateForm(), getAllowWebsiteOnServerCreateForm());
            $('.server-ip [name=port]').attr('placeholder', getDefaultPortOnServerCreateForm());
            $('.pre-game-select').show();
        }

        $('.server-form select').on('change', function()
        {
            if ($(this).val())
            {
                updateServerForm(getAllowIpOnServerCreateForm(), getAllowWebsiteOnServerCreateForm());
                $('.server-ip [name=port]').attr('placeholder', getDefaultPortOnServerCreateForm());
                $('.pre-game-select').show();
            }
            else
            {
                $('.pre-game-select').hide();
            }
        });
    }
    else
    {
        updateServerForm(
            $('.server-edit').attr('data-allow-ip'),
            $('.server-edit').attr('data-allow-website')
        );
    }
});

function updateServerForm(allowIp, allowWebsite)
{
    $('.server-ip').hide();
    $('.server-website').hide();

    if (allowIp > 0)
    {
        $('.server-ip').show();
    }
    if (allowIp > 1)
    {
        $('.server-ip .col-md-8 label').addClass('required-field');
    }
    else
    {
        $('.server-ip .col-md-8 label').removeClass('required-field');
    }
    if (allowWebsite > 0)
    {
        $('.server-website').show();
    }
    if (allowWebsite > 1)
    {
        $('.server-website label').addClass('required-field');
    }
    else
    {
        $('.server-website label').removeClass('required-field');
    }
}

function getAllowIpOnServerCreateForm(gameSelect)
{
    return $('option:selected', gameSelect).attr('data-allow-ip');
}

function getAllowWebsiteOnServerCreateForm(gameSelect)
{
    return $('option:selected', gameSelect).attr('data-allow-website');
}

function getDefaultPortOnServerCreateForm(gameSelect)
{
    return $('option:selected', gameSelect).attr('data-default-port');
}

function displayServerInfo(options)
{
    if(!Cookies.get('toggle-init'))
    {
        Cookies.set('toggle-init', 1, { expires: 365 });
        Cookies.set('toggle-description', 1, { expires: 365 });
        Cookies.set('toggle-tags', 1, { expires: 365 });
        Cookies.set('toggle-quick', 1, { expires: 365 });
    }

    options.forEach(function(option)
    {
        if(Cookies.get('toggle-' + option))
        {
            $('.sh-' + option).toggle();
            $('#server-toggle-options input[name=' + option + ']').attr('checked', 'checked');
        }
    });
}

function displayFooterBelowWindow()
{
    var headerHeight  = $('.navbar').outerHeight(),
        contentHeight = $('.content-wrapper').outerHeight(),
        combineHeight = headerHeight + contentHeight,
        windowHeight  = $(window).height();

    if(combineHeight < windowHeight)
    {
        var footer = $('.footer');

        footer.addClass('footer-absolute');
        footer.css('height', footer.outerHeight());
        footer.css('bottom', '-' + footer.outerHeight());
    }
}